<script setup>
import { API_ADDRESS_SERVER } from '@/config';
import { postWithBodyRequest } from '@/utils/restapi';
import { ref } from 'vue';
import router from '@/router.js';

const login = ref('');
const password = ref('');

async function sendLogin() {
	const response = await postWithBodyRequest(`${API_ADDRESS_SERVER}/account/login`, {
		login: login.value,
		password: password.value,
	});
	if (response) {
		localStorage.setItem('Admin Authorization', response.key);
		await router.push({ name: 'main' });
	}
}
</script>

<template>
	<main class="authorization">
		<h1 class="auth-header">Авторизация</h1>
		<div id="loginForm" class="login-form">
			<input type="text" v-model="login" name="login" placeholder="Login" required />
			<input type="password" v-model="password" name="password" placeholder="Password" required />
			<button class="green-button" @click="sendLogin">Войти</button>
		</div>
	</main>
</template>

<style>
.authorization {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: stretch;
	justify-content: space-evenly;
	padding: 10vw;
	gap: 2vw;
}

.auth-header {
	text-align: center;
	font-size: 2vw;
}
.login-form {
	background-color: #fff;
	padding: 20px;
	margin-left: 35%;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	width: 30%;
}
.login-form input[type='text'],
.login-form input[type='password'] {
	width: 100%;
	padding: 10px;
	margin: 10px 0;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}
.login-form button {
	width: 100%;
	padding: 10px;
	background-color: #28a745;
	border: none;
	border-radius: 4px;
	color: #fff;
	font-size: 16px;
	cursor: pointer;
}
</style>
