import { ref } from 'vue';
import { tonConnect, tonDisconnect, currentIsConnectedStatus } from './tonConnect';

export const connector = ref(null);
export const connectedWallet = ref(null);

export const connectToWallet = async (isRedirect = false, page = '') => {
	if (!currentIsConnectedStatus.value) {
		connector.value = await tonConnect(isRedirect, page);
		if (connector.value) {
			connector.value.connectionRestored.then(async (restored) => {
				if (restored) {
					//
				} else {
					connectedWallet.value = await connector.value.openModal();
				}
			});
		}
	}
};

export const disconnectWallet = async (isRedirect = false) => {
	if (currentIsConnectedStatus.value) {
		connectedWallet.value = await tonDisconnect(isRedirect);
	}
};

export const load = async () => {
	await connectToWallet();
};
