<script setup>
import { currentIsConnectedStatus } from '@/utils/tonConnect';
import { editBee, getAllBees } from '@/utils/bees';
import { onBeforeMount, onMounted, ref } from 'vue';
import WalletModal from '../modals/WalletModal.vue';
import { load } from '@/utils/profile';

const originalBees = ref([]);
const bees = ref([]);

const reload = async () => {
	const fetchedBees = await getAllBees();
	originalBees.value = JSON.parse(JSON.stringify(fetchedBees)); // Копируем данные для отмены изменений
	bees.value = fetchedBees;
};

const saveChanges = async () => {
	for (let i = 0; i < bees.value.length; i++) {
		if (JSON.stringify(bees.value[i]) !== JSON.stringify(originalBees.value[i])) {
			await editBee(bees.value[i]);
			originalBees.value[i] = bees.value[i]; // Обновляем оригинальные данные
		}
	}
};

const cancelChanges = () => {
	bees.value = JSON.parse(JSON.stringify(originalBees.value)); // Возвращаем оригинальные данные
};

onMounted(async () => {
	await reload();
});

onBeforeMount(async () => {
	await load();
});
</script>

<template>
	<main class="page-menu">
		<template v-if="currentIsConnectedStatus">
			<button class="green-button side-left-button" @click="reload">ОБНОВИТЬ</button>
			<div class="main-container">
				<div class="block_payments">
					<div class="container">
						<div class="group">
							<table class="block-table">
								<thead>
									<tr>
										<th>ID</th>
										<!-- <th>Тип</th> -->
										<th>Название</th>
										<!-- <th>Фото</th> -->
										<th>Цена</th>
										<th>Доход</th>
										<th>Заблокирована</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="bee in bees" :key="bee">
										<td data-label="ID">{{ bee._id }}</td>
										<!-- <td data-label="Тип">{{ bee.type }}</td> -->
										<td data-label="Название">{{ bee.name }}</td>
										<!-- <td data-label="Фото">{{ bee.image }}</td> -->
										<td data-label="Цена"><input v-model="bee.price" /> TON</td>
										<td data-label="Доход">
											<p><input v-model="bee.ton" /> TON</p>
											<p><input v-model="bee.honey" /> GH</p>
										</td>
										<td data-label="Заблокирована">
											<div class="radio-button">
												<input type="radio" id="Block" :value="true" v-model="bee.is_blocked" />
												<label for="Block">Да</label>
											</div>
											<div class="radio-button">
												<input type="radio" id="NotBlock" :value="false" v-model="bee.is_blocked" />
												<label for="NotBlock">Нет</label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div class="button-group">
								<button @click="saveChanges">Обновить</button>
								<button @click="cancelChanges">Отмена</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="wallet-button">
				<WalletModal />
			</div>
		</template>
	</main>
</template>
