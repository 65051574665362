import { deleteRequest, getRequest, postWithBodyRequest, putRequestWithBody } from './restapi';
import { API_ADDRESS_SERVER } from '@/config';

export const getAllTasks = async () =>
	await getRequest(`${API_ADDRESS_SERVER}/tasks/get/all`, false);
export const addTask = async (data) =>
	await postWithBodyRequest(`${API_ADDRESS_SERVER}/tasks/add`, data);
export const editTask = async (id, data) =>
	await putRequestWithBody(`${API_ADDRESS_SERVER}/tasks/edit/${id}`, data);
export const removeTask = async (id) =>
	await deleteRequest(`${API_ADDRESS_SERVER}/tasks/remove/${id}`);
